import { Pad } from "../Functions";

export default function ProgramRow({ program, oa = null }) {
  return (
    <a
      target="_parent"
      href={program.website_url}
      className={`p-1 
   
      `}
    >
      <div className="p-3 flex gap-4 items-start">
        {program.image && (
          <div className={"w-16 lg:w-32"}>
            <img
              src={`${
                program.image
                  ? program.image.webp
                    ? program.image.webp.tile
                    : program.image.url
                  : null
              }`}
              className={" "}
            />
          </div>
        )}
        <div className="w-full">
          <p className={` font-bold text-2xl mb-2`}>{program.name}</p>
          {program.grid_one_liner && <p>{program.grid_one_liner}</p>}
          {program.presenter_string && (
            <p>Presented by: {program.presenter_string}</p>
          )}
          {program.genre_string && <p>Genre: {program.genre_string}</p>}
          {program.language && <p>Language: {program.language}</p>}
          {program.introduction && <p>{program.introduction}</p>}
        </div>
      </div>
    </a>
  );
}
