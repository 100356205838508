import { useEffect, useState } from "react";
import "./App.css";
import ErrorMessage from "./components/Error";
import Grid from "./components/Grid";
import Loading from "./components/Loading";

function App() {
  const queryParams = new URLSearchParams(window.location.search);
  const station_id = queryParams.get("station")
    ? queryParams.get("station")
    : null;
  const highlight_color = queryParams.get("highlight_color")
    ? queryParams.get("highlight_color")
    : "000000";
  const show_genre = queryParams.get("show_genre")
    ? queryParams.get("show_genre")
    : "false";
  const show_intro = queryParams.get("show_intro")
    ? queryParams.get("show_intro")
    : "false";
  const show_language = queryParams.get("show_language")
    ? queryParams.get("show_language")
    : "false";
  const type = queryParams.get("type") ? queryParams.get("type") : "grid";
  const start = queryParams.get("start") ? queryParams.get("start") : 0;
  const [error, setError] = useState(null);
  const [guide, setGuide] = useState(null);
  const [oa, setOa] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const getStation = async () => {
      try {
        setLoading(true);
        var req = await fetch(
          `${process.env.REACT_APP_API}/public/station/${station_id}/guide`
        );
        const results = await req.json();
        if (results.success === false) {
          setLoading(false);
          setError(results.message);
        } else {
          if (results.data) {
            // console.log(results.data);
            setGuide(results.data.guide);
            setOa(results.data.onair);
            setLoading(false);
          }
        }
      } catch (error) {}
    };

    if (!guide && station_id) {
      getStation();
    }
    if (!station_id) {
      setError("No Station Id Attached");
    }
  });

  const sendPostMessage = () => {
    let height;
    setTimeout(function () {
      height = document.getElementById("GridApp").offsetHeight;
      // console.log("sending", {
      //   frameHeight: height,
      //   eventName: "mrsections",
      // });
      window.parent.postMessage(
        {
          frameHeight: height,
          eventName: "mrgrid",
        },
        "*"
      );
    }, 10);
  };

  return (
    <div id="GridApp">
      {error && <ErrorMessage error={error} />}
      {guide && oa && loading == false ? (
        <Grid
          highlight_color={highlight_color}
          show_genre={show_genre}
          show_language={show_language}
          show_intro={show_intro}
          sendPostMessage={() => sendPostMessage()}
          guide={guide}
          oa={oa}
          type={type}
        />
      ) : (
        <Loading />
      )}
    </div>
  );
}

export default App;
