import moment, { utc } from "moment-timezone";
import { useEffect, useState } from "react";
import { WeekDays, Pad } from "../Functions";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { ListBulletIcon, TableCellsIcon } from "@heroicons/react/24/solid";
import ProgramRow from "./ProgramRow";
import ProgramFilter from "./ProgramFilter";
// import "react-tabs/style/react-tabs.css";

function SortArray(x, y) {
  if (x.name < y.name) {
    return -1;
  }
  if (x.name > y.name) {
    return 1;
  }
  return 0;
}
function compare(a, b) {
  if (a.seconds_from_sunday < b.seconds_from_sunday) {
    return -1;
  }
  if (a.seconds_from_sunday > b.seconds_from_sunday) {
    return 1;
  }
  return 0;
}

export default function Grid({
  guide,
  sendPostMessage,
  oa,
  show_genre,
  show_language,
  show_intro,
  type,
  highlight_color,
}) {
  const dayNo = moment().day() - 1;

  const [actual, setActual] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  const [init, setInit] = useState(false);
  const [view, setView] = useState(type);
  const [programs, setPrograms] = useState([]);
  const [programsResults, setProgramsResults] = useState([]);
  const [filter, setFilter] = useState("");

  useEffect(() => {
    if (programsResults) {
      let Programs = programsResults;
      if (filter !== "") {
        Programs = Programs.filter((item) => {
          return (
            item.name.toLowerCase().includes(filter.toLowerCase()) ||
            item.presenter_string
              ?.toLowerCase()
              .includes(filter.toLowerCase()) ||
            item.genre_string?.toLowerCase().includes(filter.toLowerCase()) ||
            item.producer_string
              ?.toLowerCase()
              .includes(filter.toLowerCase()) ||
            item.language?.toLowerCase().includes(filter.toLowerCase())
          );
        });
      }
      setPrograms(Programs);
      sendPostMessage();
      // console.log("p", station.programs);
    }
  }, [filter]);

  useEffect(() => {
    if (init === false) {
      console.log("here now");
      setInit(true);
      const current = dayNo < 0 ? 6 : dayNo;
      setTabIndex(current);
    }
  }, [init, setInit, setTabIndex]);

  useEffect(() => {
    sendPostMessage();
  }, [tabIndex]);

  useEffect(() => {
    const setup = async () => {
      const newGuide = guide.map((item) => {
        const oldItem = { ...item, isSecond: false };
        const max = 60 * 60 * 24;
        const startSecond = item.hour_start * 60 * 60 + item.minute_start * 60;
        const startEnd = startSecond + item.duration;
        let newItem = null;
        let newDuration = item.duration;
        if (startEnd > max) {
          let newStartDay = item.weekday_start + 1;
          if (newStartDay > 6) {
            newStartDay = 0;
          }
          newItem = {
            ...item,
            start_hour: 0,
            start_minute: 0,
            duration: startEnd - max,
            newDuration: startEnd - max,
            weekday_start: newStartDay,
            isSecond: true,
            seconds_from_sunday: newStartDay * 60 * 60 * 24,
          };
          newDuration = item.duration - (startEnd - max);
        }
        return {
          ...oldItem,
          newItem: newItem,
          newDuration: newDuration,
        };
      });

      const newProg = guide.map((item) => [item.program.id, item.program]);

      let progs = [];
      newProg.forEach((element) => {
        if (!progs[element[0]]) {
          progs[element[0]] = element[1];
        }
      });

      var p = progs.sort(SortArray);
      setProgramsResults(p);
      setPrograms(p);
      console.log("here are our programs", p);
      const extras = newGuide.filter((item) => item.newItem !== null);
      const extrasFiltere = extras.map((item) => item.newItem);
      let updated = [...newGuide, ...extrasFiltere];
      updated.sort(compare);

      //   console.log(updated);

      await setActual(updated);
      sendPostMessage();
    };

    setup();
  }, [guide, setActual]);
  return (
    <div className="w-full">
      <div className="w-full hidden lg:flex justify-end gap-4 items-center">
        <p className="font-bold">TOGGLE VIEW:</p>
        <button
          className="p-2 transform transition-all gap-2 flex items-center   mb-2 mr-2 rounded-xl   group "
          onClick={() => setView(view === "grid" ? "list" : "grid")}
        >
          <ListBulletIcon
            style={{
              color: view === "grid" ? `#${highlight_color}` : `gray`,
            }}
            className={`h-8 w-8 group-hover:text-white `}
          />
          <TableCellsIcon
            style={{
              color: view !== "grid" ? `#${highlight_color}` : `gray`,
            }}
            className={`h-8 w-8  `}
          />
        </button>
      </div>
      <div
        className={`w-full justify-evenly ${
          view === "grid" ? "hidden lg:flex" : "hidden"
        } p-2 bg-gray-100 gap-1 rounded-xl`}
      >
        {WeekDays.map((day, i) => (
          <div key={i} className="flex-grow w-1/7">
            <h3 className="mb-2 font-bold">{day.day}</h3>
            <div className="day-wrapper"></div>
            {actual &&
              actual.map((program, k) => {
                const t = program.newDuration / 60 / 30;
                const height = t * 40 + t * 5 - 5;
                if (program.weekday_start === day.index) {
                  return (
                    <a
                      target="_parent"
                      href={program.program.website_url}
                      key={`p${i}${k}`}
                      style={{
                        height: `${height}px`,
                        marginBottom: "5px",
                        backgroundColor:
                          program.id === oa.slot.id
                            ? `#${highlight_color}`
                            : "white",
                        color: program.id === oa.slot.id ? `white` : "black",
                      }}
                      className={`hover:bg-gray-500 rounded-md p-1 block  overflow-hidden episode-slot`}
                    >
                      <div>
                        <p
                          className={`line font-bold leading-tight  ${
                            program.duration / 60 / 60 < 1
                              ? "text-sm"
                              : "text-lg mb-1"
                          }`}
                        >
                          {program.program.name}{" "}
                          {/* {program.duration / 60 / 60 < 1 && (
                            <span className="text-xxsm font-normal">
                              {`${Pad(
                                program.hour_start > 12
                                  ? program.hour_start - 12
                                  : program.hour_start === 0
                                  ? 12
                                  : program.hour_start
                              )}
                          :${Pad(program.minute_start)}`}
                              {program.hour_start >= 12 ? "PM" : "AM"} (
                              {program.isSecond === false
                                ? `${program.duration / 60 / 60}hrs`
                                : "cont.."}
                              )
                            </span>
                          )} */}
                        </p>
                        <p className={`text-xxsm`}>
                          {Pad(
                            program.hour_start > 12
                              ? program.hour_start - 12
                              : program.hour_start === 0
                              ? 12
                              : program.hour_start
                          )}
                          :{Pad(program.minute_start)}{" "}
                          {program.hour_start >= 12 ? "PM" : "AM"} (
                          {program.isSecond === false
                            ? `${program.duration / 60 / 60}hrs`
                            : "cont.."}
                          )
                        </p>
                        {program.duration / 60 / 60 > 1 && (
                          <p className={`mb-2 text-xxsm  `}>
                            {program.program.presenter_string}
                          </p>
                        )}
                      </div>
                    </a>
                  );
                }
              })}
          </div>
        ))}
      </div>
      <div className={`${view === "grid" ? "lg:hidden" : ""} w-full py-2`}>
        <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
          <TabList className={"flex overflow-x-scroll md:overflow-x-visible"}>
            {WeekDays.map((day, i) => (
              <Tab
                key={`${i}weekd`}
                style={{
                  backgroundColor:
                    tabIndex === i ? `#${highlight_color}` : "white",
                  color: tabIndex === i ? "white" : "black",
                }}
                className={`rounded-tl-md rounded-tr-md p-2 text-center border-0 focus:bg-black focus:text-white focus:border=0 cursor-pointer outline-none hover:bg-gray-200 hover:text-black`}
              >
                <span className="md:hidden">{day.short}</span>
                <span className="hidden md:block">{day.day}</span>
              </Tab>
            ))}
            <Tab
              style={{
                backgroundColor:
                  tabIndex === 7 ? `#${highlight_color}` : "white",
                color: tabIndex === 7 ? "white" : "black",
              }}
              className={`rounded-tl-md rounded-tr-md p-2 text-center border-0 focus:bg-black focus:text-white focus:border=0 cursor-pointer outline-none hover:bg-gray-200 hover:text-black `}
            >
              All
            </Tab>
          </TabList>
          <div className="p-2 border border-gray-200 rounded-md bg-white">
            {WeekDays.map((day, i) => (
              <TabPanel key={`${i}weekd`} className={`w-full   `}>
                <div key={i} className="flex-grow w-full">
                  <h3 className="mb-2 font-bold sr-only">{day.day}</h3>
                  <div className="day-wrapper"></div>
                  {guide &&
                    guide.map((program, k) => {
                      const t = program.newDuration / 60 / 30;
                      const height = t * 40 + t * 5 - 5;
                      if (program.weekday_start === day.index) {
                        return (
                          <a
                            target="_parent"
                            href={program.program.website_url}
                            key={`p${i}${k}`}
                            style={{
                              height: `${height}px`,
                              marginBottom: "5px",
                              backgroundColor:
                                program.id === oa.slot.id
                                  ? `#${highlight_color}`
                                  : "white",
                              color:
                                program.id === oa.slot.id ? `white` : "black",
                            }}
                            className={`p-3 flex gap-2 items-start overflow-hidden   `}
                          >
                            {program.program.image && (
                              <div
                                style={{ width: "40px" }}
                                className={" aspect-square"}
                              >
                                <img
                                  src={`${
                                    program.program.image
                                      ? program.program.image.webp
                                        ? program.program.image.webp.tile
                                        : program.program.image.url
                                      : null
                                  }`}
                                  className={"rounded-full"}
                                />
                              </div>
                            )}
                            <div className="w-full">
                              <p className={` ${"text-xs"}`}>
                                {Pad(
                                  program.hour_start > 12
                                    ? program.hour_start - 12
                                    : program.hour_start === 0
                                    ? 12
                                    : program.hour_start
                                )}
                                :{Pad(program.minute_start)}{" "}
                                {program.hour_start >= 12 ? "PM" : "AM"} (
                                {`${program.duration / 60 / 60}hrs`}}
                                {/* {program.isSecond
                          ? "12:00:00 am"
                          : `${program.hour_start}:${program.minute_start}`} */}
                              </p>
                              <p className={`font-bold  text-2xl`}>
                                {program.program.name}
                              </p>
                              <p className={` font-bold text-2xl`}>
                                {program.name}
                              </p>
                              {program.program.presenter_string && (
                                <p className={`  mb-2`}>
                                  Presented by:{" "}
                                  {program.program.presenter_string}
                                </p>
                              )}

                              {show_intro === "true" && (
                                <p className={`mt-1 text-sm md:text-base`}>
                                  {program.program.introduction}
                                </p>
                              )}
                              {show_genre === "true" && (
                                <p className={`mt-1 text-sm md:text-base`}>
                                  {program.program.genre_string}
                                </p>
                              )}
                              {show_language === "true" && (
                                <p className={`mt-1 text-sm md:text-base`}>
                                  {program.program.language}
                                </p>
                              )}
                            </div>
                          </a>
                        );
                      }
                    })}
                </div>
              </TabPanel>
            ))}
            <TabPanel className={`w-full `}>
              <div className="mt-4 px-4">
                <ProgramFilter filter={filter} setFilter={setFilter} />
              </div>
              {filter && filter !== "" && (
                <div className="mb-8 flex gap-4">
                  <div>Searching for:</div>
                  <div>{filter}</div>
                </div>
              )}
              {programs &&
                programs.map((program, i) => (
                  <ProgramRow program={program} oa={oa} key={`prog${i}`} />
                ))}
            </TabPanel>
          </div>
        </Tabs>
      </div>
      <p className="text-center p-4 text-gray-700 text-sm">
        <a href="_new" href="https://myradio.click">
          Powered by <span>MyRadio.Click</span>{" "}
        </a>
      </p>
    </div>
  );
}
