export default function ProgramFilter({ onComplete, filter, setFilter }) {
  return (
    <div className="p-filter">
      <label
        htmlFor="program-search"
        className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-gray-300"
      >
        Search
      </label>
      <div className="relative">
        <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
          <svg
            className="w-5 h-5 text-gray-900 dark:text-white"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            ></path>
          </svg>
        </div>
        <input
          type="search"
          id="program-search"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          className="block p-4 pl-10 w-full text-sm dark:text-gray-100 dark:bg-gray-700 rounded-lg border border-gray-300  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white "
          placeholder="Search our programs by name, language, genre or presenter"
          required
        />
      </div>
    </div>
  );
}
