import moment from "moment";
import "moment-timezone";

export const BLANK =
  "https://myradio-segments-scripts.s3.ap-southeast-2.amazonaws.com/1second.mp3";

export const uuidv4 = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
};

export const logEvent = async ({
  event,
  userId,
  station_id,
  episode_id = null,
  program_id = null,
  value = null,
}) => {
  let url = `${process.env.GATSBY_APP_URL}/public/stats`;
  try {
    let res = await fetch(url, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify({
        event,
        user_id: userId,
        station_id: station_id,
        source: "gatsby",
        episode_id,
        program_id,
        value,
      }), // body data type must match "Content-Type" header
    });
    const results = await res.json();
    return results;
  } catch (error) {
    console.log(error);
  }
  return true;
};

export const WeekDays = [
  { day: "Monday", index: 1, short: "Mon" },
  { day: "Tuesday", index: 2, short: "Tues" },
  { day: "Wednesday", index: 3, short: "Wed" },
  { day: "Thursday", index: 4, short: "Thur" },
  { day: "Friday", index: 5, short: "Fri" },
  { day: "Saturday", index: 6, short: "Sat" },
  { day: "Sunday", index: 0, short: "Sun" },
];

export const Pad = (d) => {
  return d < 10 ? "0" + d.toString() : d.toString();
};

export const secondsToTime = (value) => {
  const sec = parseInt(value, 10); // convert value to number if it's string
  let hours = Math.floor(sec / 3600); // get hours
  let minutes = Math.floor((sec - hours * 3600) / 60); // get minutes
  let seconds = sec - hours * 3600 - minutes * 60; //  get seconds
  // add 0 if value < 10; Example: 2 => 02
  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  return hours + ":" + minutes; // Return is HH : MM : SS
};

export const convertUTCToTimezone = (
  utcDt,
  utcDtFormat,
  timezone,
  targetFormat = "dddd, MMMM Do YYYY, h:mm:ss a"
) => {
  return moment.utc(utcDt, utcDtFormat).tz(timezone).format(targetFormat);
};

export const returnWeekday = (i) => {
  return WeekDays[i] ? WeekDays[i] : null;
};

// export default Pad;
